import React from 'react';
import ReactDOM from 'react-dom/client';
// import { StyleSheetManager } from 'styled-components';
// import nonceStylisPlugin from './Components/Compound/nonceStylisPlugin';
import App from './App.jsx';
import TestModal from './Components/View/TestModal.jsx';
import './index.css'; // Import Tailwind CSS
// FONTS
import '@fontsource/water-brush'; // Import Water Brush font
import '@fontsource/damion'; // Import Damion font
import '@fontsource/satisfy'; // Import Satisfy font
import '@fontsource/yellowtail';
import '@fontsource/cookie';
import '@fontsource/kaushan-script';
import '@fontsource/mr-dafoe';
import '@fontsource/marck-script';
import '@fontsource/allura';
import '@fontsource/arizonia';
import '@fontsource/la-belle-aurore';

const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
const nonce = nonceMetaTag ? nonceMetaTag.getAttribute('content') : null;

console.log("Nonce passed to StyleSheetManager:", nonce);

ReactDOM.createRoot(document.getElementById('root')).render(
  // <StyleSheetManager stylisPlugins={[nonceStylisPlugin]} stylisOptions={{ nonce }}>
  <>
    <App />
    {/* <TestModal /> */}
  </>
  // </StyleSheetManager>
);

