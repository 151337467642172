import React from 'react';

const SezioneCC = ({ title, className, elements }) => {
  return (
    <div className={className}>
      <h2 className="bg-gray-200 text-center text-lg font-bold py-2">{title}</h2>
      <div className="px-4 py-2 sm:px-0">
        {elements.map((element, index) => (
          <div key={index} className="border-b border-gray-200 py-1">
            <dt className="text-sm/6 font-medium text-gray-900">{element.label}</dt>
            <dd className="mt-1 ml-4 text-sm/6 text-gray-700 bg-gray-100 px-2 py-1 rounded">{element.value}</dd>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SezioneCC;