import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { PaperClipIcon } from '@heroicons/react/20/solid'

import Carousel from '../Element/Carousel'
import TavolaCC from '../Element/TavolaCC'
import SezioneCC from '../Element/SezioneCC'
import TabellaCC from '../Element/TabellaCC'

import clsx from 'clsx'
import { useState } from 'react'

const people = [
  { id: 0, name: '-' },
  { id: 1, name: 'Tom Cook' },
  { id: 2, name: 'Wade Cooper' },
  { id: 3, name: 'Tanya Fox' },
  { id: 4, name: 'Arlene Mccoy' },
  { id: 5, name: 'Devon Webb' },
]

const programmi = [
  { id: 0, name: '-' },
  { id: 1, name: 'Programma 1' },
  { id: 2, name: 'Programma 2' },
  { id: 3, name: 'Programma 3' },
  { id: 4, name: 'Programma 4' },
  { id: 5, name: 'Programma 5' },
]

const carouselItems = [
  <dd className="mt-1 ml-4 text-sm/6 text-gray-700 bg-gray-100 px-2 py-1 rounded">
    Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat.
  </dd>,
  <dd className="mt-1 ml-4 text-sm/6 text-gray-700 bg-gray-100 px-2 py-1 rounded">
    Excepteur qui ipsum aliquip consequat sint.
  </dd>,
  <dd className="mt-1 ml-4 text-sm/6 text-gray-700 bg-gray-100 px-2 py-1 rounded">
    Sit id mollit nulla mollit nostrud in ea officia proident.
  </dd>,
  <dd className="mt-1 ml-4 text-sm/6 text-gray-700 bg-gray-100 px-2 py-1 rounded">
    Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
  </dd>,
];

export default function CC() {
  const [selectedPerson, setSelectedPerson] = useState(people[0])
  const [selectedProgram, setSelectedProgram] = useState(programmi[0])

  const anagraficaElements = [
    { label: 'Nome', value: 'Pippo' },
    { label: 'Luogo e Data di Nascita', value: 'Topolinia' },
    { label: 'CF', value: 'FSIYDGF74GR4ZR' },
    { label: 'Residenza', value: 'Topolinia' },
    { label: 'Stato Civile', value: 'Celibe' },
    { label: 'Documento', value: 'CI3847563487' },
    { label: 'T. Sanitaria', value: 'rg3846rg34' },
  ];

  const clinicaElements = [
    { label: 'Data Ingresso', value: '2024-11-10' },
    { label: 'ASL', value: 'RM1' },
    { label: 'Prima Visita', value: '2024-11-9' },
    { label: 'Ingresso', value: '2024-11-10' },
    { label: 'Esenzioni ASL', value: 'no' },
    { label: 'Diagnosi di Invio', value: '101' },
    { label: 'Diagnosi di Ingresso', value: '101' },
    { label: 'Diagnosi Attuale', value: '101' },
  ];

  const referentiDsmElements = [
    { label: 'Psichiatra', value: 'Dr. Strange' },
    { label: 'Ass. soc.', value: 'Anonima' },
    { label: 'Psicologo', value: 'dr Rogers'},
  ];

  const referentiReverieElements = [
    { label: 'Medico', value: 'Dr. House' },
    { label: 'Infermiere', value: 'Nurse Joy' },
    { label: 'Educatore', value: 'Mr. Miyagi' },
  ];

  const referentiFamiliariElements = [
    { label: 'Genitore', value: 'John Doe' },
    { label: 'Coniuge', value: 'Jane Doe' },
    { label: 'Figlio', value: 'Junior Doe' },
  ];

  const tabellaFamiliariHeaders = ['Posizione', 'Nome', 'Eta\'', 'Professione', 'Altro'];
  const tabellaFamiliariRows = [
    ['mamma', 'gatta', '60', 'casalinga', ''],
    ['papa', 'castoro', '65', 'falegname', ''],
    ['zio', 'paperone', '105', '', ''],
  ];

  const tabellaValutazioniHeaders = ['BPRS', 'MMSE', 'Asse V Kennedy', 'VGF', 'GAF', 'Pericolosita', 'Altro'];
  const tabellaValutazioniRows = [
    ['80', '3', '5', '100', '5', 'si', 'no'],
  ];


  return (
    <>
    {/* SELETTORE */}
      <div className='Selettore mb-2 bg-white border-black container mx-auto flex items-center w-[90%] sm:w-[75%] md:w-[65%] lg:w-[60%] min-w-[360px] justify-center mt-0 rounded-b-xl'>
        <div className='w-52'>
          <p className='Sezione text-zinc-900 antialiased underline underline-offset-6 ml-2'>Programma</p>
          <Listbox value={selectedProgram} onChange={setSelectedProgram}>
            <ListboxButton
              className={clsx(
                'relative block w-full rounded-t-xl rounded-b-none bg-gray-100 border-black py-1.5 pr-8 pl-3 text-left text-sm/6 text-zinc-950',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-zink-950'
              )}
            >
              {selectedProgram.name}
              <ChevronDownIcon
                className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-zinc-950"
                aria-hidden="true"
              />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom"
              transition
              className={clsx(
                'w-[var(--button-width)] rounded-t-none border rounded-b-xl bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                'transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0'
              )}
            >
              {programmi.map((programma) => (
                <ListboxOption
                  key={programma.name}
                  value={programma}
                  className="group flex cursor-default items-center gap-1 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-black/10"
                >
                  <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible" />
                  <div className="text-sm/6 text-zinc-950">{programma.name}</div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </div>

        {/* // AFFIDATO */}
        <div className="w-52">
          <p className="Sezione text-zinc-900 antialiased underline underline-offset-6 ml-2">Affidato</p>
          <Listbox value={selectedPerson} onChange={setSelectedPerson}>
            <ListboxButton
              className={clsx(
                'relative block w-full rounded-t-xl rounded-b-none bg-white py-1.5 pr-8 pl-3 text-left text-sm/6 text-zinc-950',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-zink-950'
              )}
            >
              {selectedPerson.name}
              <ChevronDownIcon
                className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-zinc-950"
                aria-hidden="true"
              />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom"
              transition
              className={clsx(
                'w-[var(--button-width)] rounded-t-none border rounded-b-xl bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                'transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0'
              )}
            >
              {people.map((person) => (
                <ListboxOption
                  key={person.name}
                  value={person}
                  className="group flex cursor-default items-center gap-1 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-black/10"
                >
                  <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible" />
                  <div className="text-sm/6 text-zinc-950">{person.name}</div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </div>

      </div>
      {/* // CARTELLA CLINICA */}
      <div className='Cartella-Clinica bg-white border-black container mx-auto flex items-center w-[90%] sm:w-[75%] md:w-[65%] lg:w-[60%] min-w-[360px] justify-center mt-0 rounded-b-xl'>
        <div className='Cartella-Clinica-Body container mx-auto w-full overflow-hidden'>

          {/* HEADER CC */}
          <div className="CC-header justify-normal w-full">
            <h1 className="text-center text-xxl font-bold">CARTELLA CLINICA</h1>
            <h2 className="text-center text-lg mb-5">Nome del Soggetto</h2>
            <div className="text-right mr-1">Ultimo aggiornamento: DATA da CHI</div>
          </div>

          {/* BODY CC */}
          <div className="CC-body mt border-t border-gray-100 mx-1 my-1">
            <dl className="DIVIDE divide-y divide-gray-100">
              {/* COLONNE */}
              <div className="flex flex-wrap justify-center items-start mt-1">
                {/* COLONNA SINISTRA */}
                <div className="colonna-sinistra w-full md:w-1/2 px-2 py-0">
                  <SezioneCC title="Anagrafica" className="Anagrafica-section" elements={anagraficaElements} />
                  <SezioneCC title="Clinica" className="Clinica-section" elements={clinicaElements} />
                </div>
                {/* COLONNA DESTRA */}
                <div className="colonna-destra w-full md:w-1/2 px-2 py-0">
                    <SezioneCC title="Referenti DSM" className="refDSMsection" elements={referentiDsmElements} />
                    <SezioneCC title="Referenti Reverie" className="refReverieSection" elements={referentiReverieElements} />
                    <SezioneCC title="Referenti Familiari" className="refFamiliariSection" elements={referentiFamiliariElements} />
                    <TabellaCC title="Familiari" className="Familiari-Section" headers={tabellaFamiliariHeaders} rows={tabellaFamiliariRows} />
                </div>

              </div>
            </dl>

            {/* COLONNA IN BASSO */}
            <div className="colonna-bassa flex flex-wrap justify-center items-start mt-1">
              <TavolaCC title="Storia" items={carouselItems} />
              <TavolaCC title="Anamnesi" items={carouselItems} />
              <TavolaCC title="Status" items={carouselItems} />
              <TavolaCC title="Caratteristiche di invio" items={carouselItems} />
              <TabellaCC title="Valutazioni Strumentali" className="ValutazioniStrumentali-Section" headers={tabellaValutazioniHeaders} rows={tabellaValutazioniRows} />
              <TavolaCC title="Diari Clinici" items={carouselItems} />
              <TavolaCC title="Note Attivita' terapeutiche di gruppo" items={carouselItems} />
              <TavolaCC title="Note incontri familiari" items={carouselItems} />
              <TavolaCC title="Note incontri servizio" items={carouselItems} />
              <TavolaCC title="Note rientri e attivita' esterne" items={carouselItems} />
              <TavolaCC title="Prova" items={carouselItems} />
            </div>

          </div>
        </div >
      </div >
      
      {/* </div > */}
    </>
  )
}
