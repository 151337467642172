
// import React, { useState, useContext } from 'react';
import NavbarXTop from './NavbarXTop';
import NavbarXBottom from './NavbarXBottom';

export default function NavbarX() {

  return (
    <>
      <div className="navbar-container sticky top-0 z-50">
        <NavbarXTop />
        <NavbarXBottom />
      </div >
    </>
  );
}
