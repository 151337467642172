import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Compounds 
import { UserProvider } from './Contexts/UserContext'; // Import UserProvider
import NavbarX from "./Components/Compound/NavbarX";
import Login from './Components/View/Login';
import CartelleCliniche from './Components/View/CartelleCliniche';
import Djtemplate from './Components/View/djtemplate';
import FECV from './Components/View/FECV';
import MapView from './Components/View/MapView';
import GoogleAuthRedirect from './Components/View/GoogleAuthRedirect';
import ConvertitoreXML from './Components/View/ConvertitoreXML';
import UserList from "./Components/View/UsersList";
import BasicModal from "./Components/Element/BasicModal";
import Home from "./Components/View/Home";
import Strumenti from "./Components/View/Strumenti";

function App() {
    return (
        <UserProvider>
            <Router>
                {/* Full-screen container */}
                <div className="Desktop relative min-h-screen bg-green-900">
                    {/* Navbar */}
                    <NavbarX />
                    {/* Content Section */}
                    <div className="Content w-full pb-14">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/pazienti" element={<CartelleCliniche />} />
                            <Route path="/FECV" element={<FECV />} />
                            <Route path="/Map" element={<MapView />} />
                            <Route path="/GoogleAuthRedirect" element={<GoogleAuthRedirect />} />
                            <Route path="/convertitoreXML" element={<ConvertitoreXML />} />
                            <Route path="/djtemplate" element={<Djtemplate />} />
                            <Route path="/users" element={<UserList />} />
                            <Route path="/" element={<Home />} />
                            <Route path="/strumenti" element={<Strumenti />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </UserProvider>
    );
}

export default App;


