import React from 'react';

const InputField = ({ size, placeholder, value, onChange, name }) => {
  let inputClass = '';
  let labelClass = 'block mb-2 text-sm font-medium text-gray-900';

  switch (size) {
    case 'large':
      inputClass = 'block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-white text-base focus:ring-blue-500 focus:border-blue-500';
      break;
    case 'small':
      inputClass = 'block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-white text-xs focus:ring-blue-500 focus:border-blue-500';
      break;
    case 'default':
    default:
      inputClass = 'bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5';
      break;
  }

  return (
    <div className="mb-6">
      <input
        type="text"
        className={inputClass}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        name={name}
      />
    </div>
  );
};

export default InputField;