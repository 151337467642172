import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/another-page'); // Replace with your actual route
    };

    return (
        <div className="home-container flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <header className="home-header mb-8">
                <h1 className="text-4xl font-bold text-gray-900">Welcome to STRUMENTI</h1>
            </header>
            <main className="home-main text-center">
                <p className="text-lg text-gray-700 mb-4">A bunch of useful tools</p>
                <button
                    onClick={handleNavigate}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                    Get Started
                </button>
            </main>
        </div>
    );
};

export default Home;