import React from 'react';

const TabellaCC = ({ title, className, headers = [], rows = [] }) => {
  // Ensure rows is an array of arrays
  const validRows = Array.isArray(rows) ? rows : [];

  return (
    <div className={`overflow-x-auto my-5 ${className}`}>
      <h2 className="bg-gray-200 text-center text-lg font-bold py-2">{title}</h2>
      <table className="table-auto w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-center mb-2 mt-5">
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {validRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Array.isArray(row) ? row.map((cell, cellIndex) => (
                <td key={cellIndex} className="whitespace-nowrap px-2 py-2 text-gray-700">
                  {cell}
                </td>
              )) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TabellaCC;