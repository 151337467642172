// import React, { useState, useContext } from 'react';
// import { Disclosure, Menu } from '@headlessui/react';
// import { NavLink } from 'react-router-dom';
// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import { UserContext } from '../../Contexts/UserContext';

// import BasicModal from '../Element/BasicModal';
// import Login from '../View/Login';

// import loggedUser from '../../Assets/avatar-green.jpg';
// import loggedOutUser from '../../Assets/avatar-grey.jpg';

// // Navigation data
// const navigation = [
//   { name: 'Pazienti', to: '/pazienti' },
//   { name: 'Strumenti', to: '/strumenti' },
//   { name: 'Calendario', to: '/calendario' },
//   { name: 'Segnalazioni', to: '/segnalazioni' },
//   { name: 'Utenti', to: '/users' },
// ];

// const userNavigation = [
//   { name: 'Profilo', to: '/profilo' },
//   { name: 'Impostazioni', to: '/impostazioni' },
//   { name: 'Disconnetti', to: '#' },
// ];

// const NouserNavigation = [
//   // { name: 'Accedi', to: '/login' },
//   // Add other navigation items for non-logged-in users if needed
// ];



// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

// export default function NavbarXTop() {
//   const { isLoggedIn, user, logout, toggleLogin} = useContext(UserContext);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleOpenModal = () => setIsModalOpen(true);
//   const handleCloseModal = () => setIsModalOpen(false);

//   const userObject = isLoggedIn
//     ? { name: user.name, email: user.email, imageUrl: loggedUser }
//     : { name: 'Guest', email: 'guest@example.com', imageUrl: loggedOutUser };

//   return (
//     <>
//       <Disclosure as="nav" className="main-navbar bg-verde-reverie">
//         {({ open }) => (
//           <>
//             <div className="navbar-wrapper mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//               <div className="navbar-content flex items-center justify-between h-16">
//                 <div className="navbar-left flex items-center">
//                   <div className="navbar-links hidden md:block">
//                     <div className="navbar-navigation ml-10 flex items-baseline space-x-4">
//                       {isLoggedIn &&
//                         navigation.map((item) => (
//                           <NavLink
//                             key={item.name}
//                             to={item.to}
//                             className={({ isActive }) =>
//                               classNames(
//                                 isActive
//                                   ? 'navbar-link-active bg-gray-900 text-white'
//                                   : 'navbar-link-inactive text-gray-300 hover:bg-gray-700 hover:text-white',
//                                 'navbar-link px-3 py-2 rounded-md text-sm font-medium'
//                               )
//                             }
//                           >
//                             {item.name}
//                           </NavLink>
//                         ))}
//                     </div>
//                   </div>
//                 </div>

//                 {/* Bell Icon and Profile Dropdown */}
//                 <div className="navbar-right hidden md:block">
//                   <div className="navbar-actions ml-4 flex items-center md:ml-6">
//                     <button
//                       type="button"
//                       className="notification-icon rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                       onClick={toggleLogin} // Toggle login status on click
//                     >
//                       <span className="sr-only">Toggle Login</span>
//                       <BellIcon className="icon h-6 w-6" aria-hidden="true" />
//                     </button>

//                     <Menu as="div" className="profile-menu relative ml-3">
//                       <div>
//                         <Menu.Button className="profile-menu-button flex max-w-xs items-center rounded-full bg-gray-800 text-sm">
//                           <img
//                             className="profile-avatar h-8 w-8 rounded-full"
//                             src={userObject.imageUrl}
//                             alt=""
//                           />
//                         </Menu.Button>
//                       </div>

//                       <Menu.Items className="profile-dropdown z-50 absolute right-0 mt-2 w-48 origin-top-right bg-white rounded-md py-1 shadow-lg">
//                         {isLoggedIn
//                           ? userNavigation.map((item) => (
//                             <Menu.Item key={item.name}>
//                               {({ active }) => (
//                                 <button
//                                   onClick={item.name === 'Disconnetti' ? logout : undefined}
//                                   className={classNames(
//                                     active ? 'dropdown-item-active bg-gray-100' : '',
//                                     'dropdown-item block px-4 py-2 text-sm text-gray-700'
//                                   )}
//                                 >
//                                   {item.name}
//                                 </button>
//                               )}
//                             </Menu.Item>
//                           ))
//                           : [
//                             <Menu.Item key="Accedi">
//                               {({ active }) => (
//                                 <button
//                                   onClick={handleOpenModal}
//                                   className={classNames(
//                                     active ? 'dropdown-item-active bg-gray-100' : '',
//                                     'dropdown-item block px-4 py-2 text-sm text-gray-700'
//                                   )}
//                                 >
//                                   Accedi
//                                 </button>
//                               )}
//                             </Menu.Item>,
//                           ]}
//                       </Menu.Items>
//                     </Menu>
//                   </div>
//                 </div>

//                 {/* Mobile Menu Button */}
//                 <div className="mobile-menu-button -mr-2 flex md:hidden">
//                   <Disclosure.Button className="mobile-menu-toggler inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                     <span className="sr-only">Open main menu</span>
//                     {open ? (
//                       <XMarkIcon className="icon block h-6 w-6" aria-hidden="true" />
//                     ) : (
//                       <Bars3Icon className="icon block h-6 w-6" aria-hidden="true" />
//                     )}
//                   </Disclosure.Button>
//                 </div>
//               </div>
//             </div>

//             {/* Mobile Menu */}
//             <Disclosure.Panel className="mobile-menu md:hidden">
//               <div className="mobile-menu-links space-y-1 px-2 pt-2 pb-3 sm:px-3">
//                 {isLoggedIn &&
//                   navigation.map((item) => (
//                     <NavLink
//                       key={item.name}
//                       to={item.to}
//                       className={({ isActive }) =>
//                         classNames(
//                           isActive
//                             ? 'mobile-link-active bg-gray-900 text-white'
//                             : 'mobile-link-inactive text-gray-300 hover:bg-gray-700 hover:text-white',
//                           'mobile-link block px-3 py-2 rounded-md text-base font-medium'
//                         )
//                       }
//                     >
//                       {item.name}
//                     </NavLink>
//                   ))}
//               </div>

//               <div className="mobile-user-info border-t border-gray-700 pt-4 pb-3">
//                 <div className="mobile-profile flex items-center px-5">
//                   <div className="mobile-avatar-container flex-shrink-0">
//                     <img className="mobile-avatar h-10 w-10 rounded-full" src={user ? loggedUser : loggedOutUser} alt="" />
//                   </div>
//                   <div className="mobile-user-details ml-3">
//                     <div className="mobile-username text-base font-medium leading-none text-white">{user ? user.name : 'Guest'}</div>
//                     <div className="mobile-email text-sm font-medium leading-none text-gray-400">{user ? user.email : 'guest@example.com'}</div>
//                   </div>
//                 </div>

//                 <div className="mt-3 space-y-1 px-2">
//                   {isLoggedIn ? (
//                     userNavigation.map((item) => (
//                       <Disclosure.Button
//                         key={item.name}
//                         as={NavLink}
//                         to={item.to}
//                         className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                         onClick={item.name === 'Disconnetti' ? logout : undefined}
//                       >
//                         {item.name}
//                       </Disclosure.Button>
//                     ))
//                   ) : (
//                     <>
//                       <Disclosure.Button
//                         as="button"
//                         onClick={handleOpenModal}
//                         className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                       >
//                         Accedi
//                       </Disclosure.Button>
//                     </>
//                   )}
//                 </div>
//               </div>
//             </Disclosure.Panel>
//           </>
//         )}
//       </Disclosure>

//       {/* Login Modal */}
//       <BasicModal isOpen={isModalOpen} onClose={handleCloseModal} title="Accedi">
//         <Login />
//       </BasicModal>
//     </>
//   );
// }


import React, { useState, useContext } from 'react';
import { Disclosure, Menu } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../../Contexts/UserContext';

import BasicModal from '../Element/BasicModal';
import Login from '../View/Login';

import loggedUser from '../../Assets/avatar-green.jpg';
import loggedOutUser from '../../Assets/avatar-grey.jpg';

// Navigation data
const navigation = [
  { name: 'Pazienti', to: '/pazienti' },
  { name: 'Strumenti', to: '/strumenti' },
  { name: 'Calendario', to: '/calendario' },
  { name: 'Segnalazioni', to: '/segnalazioni' },
  { name: 'Utenti', to: '/users' },
];

const userNavigation = [
  { name: 'Profilo', to: '/profilo' },
  { name: 'Impostazioni', to: '/impostazioni' },
  { name: 'Disconnetti', to: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavbarXTop() {
  const { isLoggedIn, user, logout, toggleLogin } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const userObject = isLoggedIn
    ? { name: user.name, email: user.email, imageUrl: loggedUser }
    : { name: 'Guest', email: 'guest@example.com', imageUrl: loggedOutUser };

  return (
    <>
      <Disclosure as="nav" className="main-navbar bg-verde-reverie">
        {({ open }) => (
          <>
            <div className="navbar-wrapper mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="navbar-content flex items-center justify-between h-16">
                <div className="navbar-left flex items-center">
                  <div className="navbar-links hidden md:block">
                    <div className="navbar-navigation ml-10 flex items-baseline space-x-4">
                      {isLoggedIn &&
                        navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.to}
                            className={({ isActive }) =>
                              classNames(
                                isActive
                                  ? 'navbar-link-active bg-gray-900 text-white'
                                  : 'navbar-link-inactive text-gray-300 hover:bg-gray-700 hover:text-white',
                                'navbar-link px-3 py-2 rounded-md text-sm font-medium'
                              )
                            }
                          >
                            {item.name}
                          </NavLink>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="navbar-right hidden md:block">

                  <div className="navbar-actions ml-4 flex items-center md:ml-6">

                    {/* bottone notifica */}
                    <button
                      type="button"
                      className="notification-icon rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      onClick={toggleLogin} // Toggle login status on click
                    >
                      <span className="sr-only">Toggle Login</span>
                      <BellIcon className="icon h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* bottone profilo */}
                    <Menu as="div" className="profile-button relative ml-3">
                      <div>
                        <Menu.Button className="profile-menu-button flex max-w-xs items-center rounded-full bg-gray-800 text-sm">
                          <img
                            className="profile-avatar h-8 w-8 rounded-full"
                            src={userObject.imageUrl}
                            alt=""
                          />
                        </Menu.Button>
                      </div>

                      {/* DROPDOWN MENU */}
                      {/* usernavigation map seleziona l'elemento corrente. */}
                      <Menu.Items className="profile-dropdown absolute right-0 mt-2 w-48 origin-top-right bg-white rounded-md py-1 shadow-lg z-50">
                        {isLoggedIn
                          ? userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <button
                                  onClick={item.name === 'Disconnetti' ? logout : undefined}
                                  className={classNames(
                                    active ? 'dropdown-item-active bg-gray-100' : '',
                                    'dropdown-item block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))
                          : (
                            <Menu.Item key="Accedi">
                              {({ active }) => (
                                <button
                                  onClick={handleOpenModal}
                                  className={classNames(
                                    active ? 'dropdown-item-active bg-gray-100' : '',
                                    'dropdown-item block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  Accedi
                                </button>
                              )}
                            </Menu.Item>
                          )}
                      </Menu.Items>
                      {/* DROPDOWN MENU END */}

                    </Menu>
                  </div>
                </div>

                {/* Mobile Menu Button */}
                <div className="mobile-menu-button -mr-2 flex md:hidden">
                  <Disclosure.Button className="mobile-menu-toggler inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="icon block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="icon block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            {/* Mobile Menu */}
            <Disclosure.Panel className="mobile-menu md:hidden">
              <div className="mobile-menu-links space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {isLoggedIn &&
                  navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.to}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? 'mobile-link-active bg-gray-900 text-white'
                            : 'mobile-link-inactive text-gray-300 hover:bg-gray-700 hover:text-white',
                          'mobile-link block px-3 py-2 rounded-md text-base font-medium'
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  ))}
              </div>
              <div className="mobile-user-info border-t border-gray-700 pt-4 pb-3">
                <div className="mobile-profile flex items-center px-5">
                  <div className="mobile-avatar-container flex-shrink-0">
                    <img className="mobile-avatar h-10 w-10 rounded-full" src={user ? loggedUser : loggedOutUser} alt="" />
                  </div>
                  <div className="mobile-user-details ml-3">
                    <div className="mobile-username text-base font-medium leading-none text-white">{user ? user.name : 'Guest'}</div>
                    <div className="mobile-email text-sm font-medium leading-none text-gray-400">{user ? user.email : 'guest@example.com'}</div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {isLoggedIn ? (
                    userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as={NavLink}
                        to={item.to}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        onClick={item.name === 'Disconnetti' ? logout : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))
                  ) : (
                    <Disclosure.Button
                      as="button"
                      onClick={handleOpenModal}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Accedi
                    </Disclosure.Button>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Login Modal */}
      <BasicModal isOpen={isModalOpen} onClose={handleCloseModal} title="Accedi">
        <Login />
      </BasicModal>
    </>
  );
}