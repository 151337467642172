import React from 'react';
import Carousel from './Carousel';

const TavolaCC = ({ title, items }) => {
  return (
    <div className="w-full px-4 py-2 sm:px-0 mb-2">
      <h2 className="bg-gray-200 text-center text-lg font-bold py-2">{title}</h2>
      <div className="mt-1 ml-4 text-sm/6 text-gray-700 bg-gray-100 px-2 py-1 rounded">
        <Carousel items={items} />
      </div>
    </div>
  );
};

export default TavolaCC;